import moment, { Moment } from 'moment';

import { DTO, lowerCase, MapFromDTO, Mapper, MetadataEntity, momentMapper } from '@bp/shared/models/metadata';
import { uuid } from '@bp/shared/utilities/core';

import { GtagEvents } from '@bp/frontend/features/analytics/models';

export abstract class EventsTrackingAnalytics extends MetadataEntity {

	static buildAnalyticsEventDTO(event: GtagEvents.List): DTO<EventsTrackingAnalytics> {
		return {
			lastEventName: event,
			lastEventId: uuid(),
			lastEventTimestamp: moment(),
		};
	}

	@MapFromDTO()
	lastEventId?: string;

	@Mapper(lowerCase)
	lastEventName?: GtagEvents.List;

	@Mapper(momentMapper)
	lastEventTimestamp?: Moment;

	readonly lastEventTimestampFormatted?: string;

	@MapFromDTO()
	dispatchedEventsCountMap?: {[key in GtagEvents.List]?: number };

	@MapFromDTO()
	dispatchedEventsCountChangeTimestampMap?: {[key in GtagEvents.List]?: number };

	constructor(dto?: DTO<EventsTrackingAnalytics>) {
		super(dto);

		if (this.lastEventTimestamp)
			this.lastEventTimestampFormatted = this.lastEventTimestamp.format('LLL');
	}

}
