import { createAction, props } from '@ngrx/store';

import { CrmOrganization, CrmOrganizationAnalytics } from '@bp/frontend/domains/crm/organizations/models';
import { createVerboseAction } from '@bp/frontend/ngrx';

import { ACTIONS_SCOPE } from './current-crm-organization-api.actions';

export const observeCrmOrganizationRemoteChanges = createAction(
	`${ ACTIONS_SCOPE } Observe Crm Organization Remote Changes`,
	props<{ organizationId: string }>(),
);

export const stopObservingCrmOrganizationRemoteChanges = createAction(
	`${ ACTIONS_SCOPE } Stop Observing Crm Organization Remote Changes`,
);

export const updateCrmOrganizationInStore = createVerboseAction(
	`${ ACTIONS_SCOPE } Update Crm Organization In Store`,
	props<{ organization: CrmOrganization }>(),
);

export const trySaveCrmOrganizationKeptInStore = createVerboseAction(
	`${ ACTIONS_SCOPE } Try Save Crm Organization Kept In Store`,
	props<{ organization: CrmOrganization | null }>(),
);

export const saveCrmOrganizationKeptInStore = createVerboseAction(
	`${ ACTIONS_SCOPE } Save Crm Organization Kept In Store`,
	props<{ organization: CrmOrganization }>(),
);

export const trySaveCrmOrganizationAnalytics = createVerboseAction(
	`${ ACTIONS_SCOPE } Try Save Crm Organization Analytics`,
	props<{ analytics: CrmOrganizationAnalytics }>(),
);

export const saveCrmOrganizationAnalytics = createAction(
	`${ ACTIONS_SCOPE } Save Crm Organization Analytics`,
	props<{ organization: CrmOrganization }>(),
);

export const resetState = createAction(
	`${ ACTIONS_SCOPE } Reset State`,
);
