import { Moment } from 'moment';

import { DTO, FieldViewType, IdentifiableEntity, MapFromDTO, Mapper, momentMapper, View } from '@bp/shared/models/metadata';

import { toFirebasePlainObject } from '@bp/frontend/features/firebase/utils';

export abstract class FirebaseDocument extends IdentifiableEntity {

	@Mapper(momentMapper)
	@View(FieldViewType.moment, () => 'LLL')
	createdAt?: Moment;

	readonly createdAtFormatted?: string;

	@Mapper(momentMapper)
	@View(FieldViewType.moment, () => 'LLL')
	updatedAt?: Moment;

	readonly updatedAtFormatted?: string;

	@MapFromDTO()
	updatedBy?: string;

	constructor(dto?: DTO<FirebaseDocument>) {
		super(dto);

		this.createdAtFormatted = this.createdAt?.format('LLL');

		this.updatedAtFormatted = this.updatedAt?.format('LLL');
	}

	toFirebasePlainObject(): DTO<this> {
		return <DTO<this>>toFirebasePlainObject(this);
	}

}
