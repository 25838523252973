import { Country, State, stateMapper } from '@bp/shared/models/countries';
import { DTO, MapFromDTO, Mapper, MetadataEntity } from '@bp/shared/models/metadata';

export class CrmOrganizationBillingDetails extends MetadataEntity {

	static {
		this._initClassMetadata();
	}

	@MapFromDTO()
	address!: string;

	@MapFromDTO()
	city!: string;

	@MapFromDTO()
	zipCode!: string;

	@Mapper(Country)
	country!: Country | null;

	@Mapper(stateMapper)
	state!: State | null;

	@MapFromDTO()
    vatId?: string;

	constructor(dto?: DTO<CrmOrganizationBillingDetails>) {
		super(dto);
	}

}
