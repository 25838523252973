import { isNumber, isNil } from 'lodash-es';

import { AliasFor, DTO, Mapper, Unserializable } from '@bp/shared/models/metadata';
import { SubscriptionPlan, SubscriptionFeaturesLimitsKeys } from '@bp/shared/domains/subscription-plans/core';

import { CurrentOrganizationSubscriptionFeatureLimit } from './current-organization-subscription-feature-limit';
import { OrganizationSubscription } from './organization-subscription';

export class CurrentOrganizationSubscription extends OrganizationSubscription {

	static {
		this._initClassMetadata();
	}

	@Mapper(SubscriptionPlan)
	@AliasFor('subscription', { serializeAliasSourceProperty: false })
	subscriptionPlan!: SubscriptionPlan;

	get isEnterprise(): boolean {
		return this.subscriptionPlan.isEnterprise;
	}

	get isFree(): boolean {
		return this.subscriptionPlan.isFree;
	}

	get isGrowth(): boolean {
		return this.subscriptionPlan.isGrowth;
	}

	@Unserializable()
	readonly featureLimitsMap = this.__aggregateFeatureLimits();

	readonly monthPrice = this.__getMonthPrice();

	readonly chargePeriodPrice = this.__getChargePeriodPrice();

	constructor(dto?: DTO<CurrentOrganizationSubscription>) {
		super(dto);
	}

	getFeatureLimit(featureName: SubscriptionFeaturesLimitsKeys): CurrentOrganizationSubscriptionFeatureLimit {
		return this.featureLimitsMap.get(featureName)!;
	}

	private __getMonthPrice(): number {
		return this.subscriptionPlan.getMonthPriceFor(this.chargePeriod, this.currency);
	}

	private __getChargePeriodPrice(): number {
		return this.subscriptionPlan.prices?.get(this.chargePeriod)?.getPriceFor(this.currency) ?? 0;
	}

	private __aggregateFeatureLimits(): Map<SubscriptionFeaturesLimitsKeys, CurrentOrganizationSubscriptionFeatureLimit> {
		return new Map(
			Object
				.entries(this.limitsCounters)
				.filter((entry): entry is [SubscriptionFeaturesLimitsKeys, number] => isNumber(entry[1]) || isNil(entry[1]))
				.map(([ featureName, counter ]) => {
					const featureLabel = this.limitsCounters.classMetadata.get(featureName)!.label!;
					const featureLimit = this.subscriptionPlan.limits[featureName];

					return [
						featureName,
						new CurrentOrganizationSubscriptionFeatureLimit({
							featureName,
							featureLabel,
							featureDescription: this.subscriptionPlan.features
								.find(feature => feature.name === featureLabel)?.description,
							counter,
							limit: featureLimit ? Number(featureLimit) : null,
						}),
					];
				}),
		);
	}
}
