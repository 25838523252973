import { createAction, props } from '@ngrx/store';

import { ErrorActionPayload } from '@bp/frontend/models/common';

export const ACTIONS_SCOPE = '[Domain][Current Crm Organization]';

const scope = `[API]${ ACTIONS_SCOPE }`;

export const saveCrmOrganizationSuccess = createAction(
	`${ scope } Save Crm Organization Kept In Store Success`,
);

export const saveCrmOrganizationFailure = createAction(
	`${ scope } Save Crm Organization Kept In Store Failure`,
	props<ErrorActionPayload>(),
);

export const saveCrmOrganizationAnalyticsSuccess = createAction(
	`${ scope } Save Crm Organization Analytics Success`,
);

export const saveCrmOrganizationAnalyticsFailure = createAction(
	`${ scope } Save Crm Organization Analytics Failure`,
	props<ErrorActionPayload>(),
);

export const resetState = createAction(
	`${ ACTIONS_SCOPE } Reset State`,
);
