
import { DTO } from '@bp/shared/models/metadata';

import { EventsTrackingAnalytics } from '@bp/frontend/features/firebase/models';

export type CrmOrganizationAnalyticsDTO = DTO<CrmOrganizationAnalytics>;

export class CrmOrganizationAnalytics extends EventsTrackingAnalytics {

	static {
		this._initClassMetadata();
	}

	constructor(dto?: CrmOrganizationAnalyticsDTO) {
		super(dto);
	}

}
