import { createFeature, createReducer, on } from '@ngrx/store';

import { CrmOrganization, CrmOrganizationAnalytics } from '@bp/frontend/domains/crm/organizations/models';
import { BpError } from '@bp/frontend/models/core';

import {
	observeCrmOrganizationRemoteChanges, resetState, saveCrmOrganizationKeptInStore, updateCrmOrganizationInStore, trySaveCrmOrganizationAnalytics
} from './current-crm-organization.actions';
import { saveCrmOrganizationFailure, saveCrmOrganizationSuccess } from './current-crm-organization-api.actions';

export interface IState {

	organization: CrmOrganization | null;

	analytics: CrmOrganizationAnalytics | null;

	observedOrganizationId: string | null;

	pending: boolean;

	error: BpError | null;

}

export const initialState: IState = {
	organization: null,
	analytics: null,
	observedOrganizationId: null,
	pending: false,
	error: null,
};

export const currentCrmOrganizationFeature = createFeature({
	name: '[domain]:current-crm-organization',
	reducer: createReducer(
		initialState,

		on(resetState, (): IState => initialState),

		on(updateCrmOrganizationInStore, (state, { organization }): IState => ({
			...state,
			organization,
		})),

		on(saveCrmOrganizationKeptInStore, (state): IState => ({
			...state,
			pending: true,
			error: null,
		})),

		on(
			saveCrmOrganizationSuccess,
			saveCrmOrganizationFailure,
			(state): IState => ({
				...state,
				pending: false,
			}),
		),

		on(
			saveCrmOrganizationFailure,
			(state, { error }): IState => ({
				...state,
				error,
			}),
		),

		on(
			observeCrmOrganizationRemoteChanges,
			(state, { organizationId }): IState => ({
				...state,
				observedOrganizationId: organizationId,
			}),
		),

		on(
			trySaveCrmOrganizationAnalytics,
			(state, { analytics }): IState => ({
				...state,
				analytics: new CrmOrganizationAnalytics({
					...state.analytics,
					...analytics,
				}),
			}),
		),
	),
});
