import { Enumeration } from '@bp/shared/models/core/enum';

export class CrmOrganizationStatus extends Enumeration {

	static readonly active = new CrmOrganizationStatus();

	static readonly warning = new CrmOrganizationStatus();

	static readonly suspended = new CrmOrganizationStatus();

	get isActive(): boolean {
		return this === CrmOrganizationStatus.active;
	}

	get isWarning(): boolean {
		return this === CrmOrganizationStatus.warning;
	}

	get isSuspended(): boolean {
		return this === CrmOrganizationStatus.suspended;
	}
}
