import moment, { Moment } from 'moment';

import { MockUserEmail, MOCK_USER_EMAILS } from '@bp/shared/domains/jwt-session';
import { CurrentOrganizationSubscription } from '@bp/shared/domains/organizations';
import { DTO, MapFromDTO, Mapper, momentMapper, Unserializable } from '@bp/shared/models/metadata';
import { NonFunctionPropertyNames } from '@bp/shared/typings';

import { FirebaseDocument } from '@bp/frontend/features/firebase/models';

import { CrmOrganizationAnalytics } from './crm-organization-analytics';
import { CrmOrganizationBillingDetails } from './crm-organization-billing-details';
import { CrmOrganizationStatus } from './crm-organization-status';
import { CrmOrganizationCheckout } from './crm-organization-checkouts';

export type CrmOrganizationKeys = NonFunctionPropertyNames<CrmOrganization>;

export type CrmOrganizationDTO = DTO<CrmOrganization>;

/**
 * An entity to store organization related information to pass to the CRM and from the CRM.
 */
export class CrmOrganization extends FirebaseDocument {

	static omitFieldsThatCanBeSetOnlyByCrm<T extends CrmOrganization | CrmOrganizationDTO>(dto: T): T {
		delete dto.status;

		delete dto.suspensionDate;

		return dto;
	}

	static {
		this._initClassMetadata();
	}

	@MapFromDTO()
	companyName!: string;

	@MapFromDTO()
	companyWebsite!: string;

	@MapFromDTO()
	ownerId?: string;

	@MapFromDTO()
	ownerEmail?: string;

	@Mapper(CrmOrganizationBillingDetails)
	billingDetails!: CrmOrganizationBillingDetails;

	@Mapper(CurrentOrganizationSubscription)
	subscription?: CurrentOrganizationSubscription;

	@MapFromDTO()
	wizardSetupId?: string;

	@Mapper(CrmOrganizationAnalytics)
	analytics?: CrmOrganizationAnalytics;

	@MapFromDTO()
	activatedPlugins?: string[];

	@MapFromDTO()
	psps?: { name: string; type: string }[];

	@Mapper(CrmOrganizationCheckout)
	checkouts?: CrmOrganizationCheckout[];

	// #region Set only by CRM fields

	@Mapper(CrmOrganizationStatus)
	status?: CrmOrganizationStatus;

	@Mapper(momentMapper)
	suspensionDate?: Moment;

	// #endregion

	@Unserializable()
	readonly suspensionDateInFuture?: Moment;

	constructor(dto?: CrmOrganizationDTO) {
		super(dto);

		this.suspensionDateInFuture = this.suspensionDate?.isAfter(moment())
			? this.suspensionDate
			: undefined;
	}

	isTest(): boolean {
		return this.ownerEmail?.search(/test|bridgerpay/gu) !== -1;
	}

	isMockTest(): boolean {
		return MOCK_USER_EMAILS.includes(<MockUserEmail> this.ownerEmail!);
	}
}
