import { Default, DTO, MapFromDTO, MetadataEntity } from '@bp/shared/models/metadata';

export class CurrentOrganizationSubscriptionFeatureLimit extends MetadataEntity {

	static warningThreshold = 0.75;

	static dangerThreshold = 0.9;

	@MapFromDTO()
	featureName!: string;

	@MapFromDTO()
	featureLabel!: string;

	@Default(null)
	featureDescription!: string | null;

	@Default(null)
	limit!: number | null; // null unlimited

	@MapFromDTO()
	counter!: number;

	get isNotUnlimited(): boolean {
		return this.limit !== null;
	}

	readonly isWarningThresholdReached: boolean = false;

	readonly isDangerThresholdReached: boolean = false;

	readonly counterToLimitString: string;

	readonly counterToLimitPercent: number | null;

	readonly leftToLimit: number | null;

	constructor(dto: DTO<CurrentOrganizationSubscriptionFeatureLimit>) {
		super(dto);

		this.leftToLimit = this.limit ? this.limit - this.counter : null;

		this.counterToLimitPercent = this.limit
			? Math.min(1, this.counter / this.limit)
			: null;

		this.counterToLimitString = `${ this.counter } / ${ this.limit ?? '∞' }`;

		if (this.counterToLimitPercent) {
			this.isWarningThresholdReached = this.counterToLimitPercent >= CurrentOrganizationSubscriptionFeatureLimit.warningThreshold
				&& this.counterToLimitPercent < CurrentOrganizationSubscriptionFeatureLimit.dangerThreshold;

			this.isDangerThresholdReached = this.counterToLimitPercent >= CurrentOrganizationSubscriptionFeatureLimit.dangerThreshold;
		}
	}
}
