import { values } from 'lodash-es';

import { toPlainObject, isDictionaryObject } from '@bp/shared/utilities/core';

/**
 * - Converts all properties with empty object values to undefined
 */
export function toFirebasePlainObject<T extends object >(target: T): Record<string, any> {
	return toPlainObject(
		target,
		(propertyName, propertyValue) => ifEmptyObjectReturnUndefined(propertyValue),
	);
}

/**
 * If a saving document has a property with an empty object value it will be erased instead of merging into it by firebase,
 * so we need to replace it with undefined so the property will be intact when merging to firebase existing document
 */
function ifEmptyObjectReturnUndefined(propertyValue: unknown): unknown {
	if (!isDictionaryObject(propertyValue))
		return propertyValue;

	const hasSomeValues = values(propertyValue)
		.some((value: unknown) => value !== undefined);

	return hasSomeValues ? propertyValue : undefined;
}
