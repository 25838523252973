import { distinctUntilChanged, lastValueFrom, shareReplay, first } from 'rxjs';

import { inject, Injectable } from '@angular/core';

import { Store } from '@ngrx/store';

import { isEqual } from '@bp/shared/utilities/core';
import { DTO } from '@bp/shared/models/metadata';

import { CrmOrganization, CrmOrganizationAnalytics } from '@bp/frontend/domains/crm/organizations/models';
import { takePresent } from '@bp/frontend/rxjs';

import {
	observeCrmOrganizationRemoteChanges, resetState, stopObservingCrmOrganizationRemoteChanges, trySaveCrmOrganizationKeptInStore, updateCrmOrganizationInStore
} from './current-crm-organization.actions';
import { currentCrmOrganizationFeature, IState } from './current-crm-organization.feature';

/**
 * State management for the current user stored in firebase
 */
@Injectable({
	providedIn: 'root',
})
export class CurrentCrmOrganizationFacade {

	private readonly __store$ = inject<Store<IState>>(Store);

	organization$ = this.__store$.select(currentCrmOrganizationFeature.selectOrganization);

	organizationPresent$ = this.organization$.pipe(takePresent);

	organization: CrmOrganization | null = null;

	analytics$ = this.__store$
		.select(currentCrmOrganizationFeature.selectAnalytics)
		.pipe(
			distinctUntilChanged(isEqual),
			shareReplay({ bufferSize: 1, refCount: false }),
		);

	analytics: CrmOrganizationAnalytics | null = null;

	constructor() {
		this.__keepOrganizationPropertyUpdated();

		this.__keepAnalyticsPropertyUpdated();
	}

	factory = (dto?: DTO<CrmOrganization>): CrmOrganization => new CrmOrganization(dto);

	observeCrmOrganizationRemoteChanges(organizationId: string): void {
		this.__store$.dispatch(observeCrmOrganizationRemoteChanges({
			organizationId,
		}));
	}

	async updateOrganizationInStore(partialOrganization: DTO<CrmOrganization>): Promise<void> {
		const updateOrganizationDto = !!partialOrganization.id && this.organization?.id !== partialOrganization.id
			? partialOrganization
			: {
				...this.organization,
				...partialOrganization,
				analytics: {
					...this.organization?.analytics,
					...partialOrganization.analytics,
				},
			};

		const updatedOrganization = this.factory(updateOrganizationDto);

		this.__store$.dispatch(updateCrmOrganizationInStore({
			organization: updatedOrganization,
		}));

		await lastValueFrom(this.organization$.pipe(
			first(organization => organization === updatedOrganization),
		));
	}

	resetState(): void {
		this.__store$.dispatch(resetState());

		this.__store$.dispatch(stopObservingCrmOrganizationRemoteChanges());
	}

	saveOrganizationKeptInStore(): void {
		this.__store$.dispatch(trySaveCrmOrganizationKeptInStore({
			organization: this.organization,
		}));
	}

	async updateAndSaveOrganizationKeptInStore(partialOrganization: DTO<CrmOrganization>): Promise<void> {
		await this.updateOrganizationInStore(partialOrganization);

		this.saveOrganizationKeptInStore();
	}

	private __keepOrganizationPropertyUpdated(): void {
		this.organization$.subscribe(organization => (this.organization = organization));
	}

	private __keepAnalyticsPropertyUpdated(): void {
		this.analytics$.subscribe(analytics => (this.analytics = analytics));
	}

}
